import useHttpClient from "components/hooks/useHttpClient";
import { BATCH_SYNCHRONIZE_URL } from "components/priceCheck/mockData.js/constants";
import { useState } from "react";
import usePriceCheckStore from "stores/priceCheckStore";

const SUCCESS_MESSAGE = "Uppdateringen lyckades";
const ERROR_MESSAGE = "Misslyckades med att uppdatera poster";
const RETRY_BUTTON_TEXT = "Försök igen";

const useRegeneratePriceComparisons = () => {
  const { makeHttpRequest } = useHttpClient();
  const { selectedUpdateItems, setSelectedUpdateItems } = usePriceCheckStore();
  const selectedUpdateItemsCount = Object.keys(selectedUpdateItems || {}).length;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successResponseMessage, setSuccessResponseMessage] = useState<string | null>(null);
  const [status, setStatus] = useState<number | null>(null);

  // Function to construct query string from selectedUpdateItems
  const createPriceTrackerIDsString = () => 
    Object.keys(selectedUpdateItems || {})
      .map((id) => `priceTrackerIds=${id}`)
      .join('&');

  // Function to send PUT request
  const updatePriceComparisonItems = async () => {
    setLoading(true);
    setError(null);
    setSuccessResponseMessage(null);
    setStatus(null);

    const endpoint = `${BATCH_SYNCHRONIZE_URL}?${createPriceTrackerIDsString()}`;

    try {
      const response = await makeHttpRequest("PUT", endpoint);
      // if (response?.status >= 200 && response?.status < 300) {
        setSuccessResponseMessage(SUCCESS_MESSAGE);
        setSelectedUpdateItems({}); // Clear selected items after successful update
        setStatus(response?.status); // Set the actual status from the response
      // } else {
      //   throw new Error(response?.data?.message || ERROR_MESSAGE);
      // }
    } catch (err: any) {
      // Handle error and set appropriate state
      setError(err?.response?.data?.message || ERROR_MESSAGE);
      setStatus(err?.response?.status || 500);
    } finally {
      setLoading(false); // Always stop loading after request is done
    }
  };

  return {
    selectedUpdateItemsCount,
    loading,
    successResponseMessage,
    error,
    status,
    updatePriceComparisonItems,
  };
};

export default useRegeneratePriceComparisons;
