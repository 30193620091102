import React from "react";
import { Drawer, Button, Space, Tooltip } from "antd";
import { Download, XCircle } from "lucide-react";
import styled from "@emotion/styled";
import { DividerLine } from "components/separators/dividerLine";
import usePriceCheckStore from "stores/priceCheckStore";
import { calculatePercentageDiff } from "utility/helper";
import { PRICE_CHECK_THEME_COLORS } from "utility/priceCheck/constants";

const DrawerStyled = styled(Drawer)`
  .ant-drawer-title {
    font-size: 18px;
  }
  .ant-drawer-body {
    padding: 0 24px;
    /* @media (max-width: 800px) {
      padding: 0;
    } */
  }
`;

const TitleStyled = styled.span`
  font-family: "HK Grotesk Bold";
  color: black;
  font-size: 1.25rem;
  margin: auto 0;
`;

const ButtonStyled = styled(Button)`
  justify-content: center;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 1.1rem;
  font-size: 1rem;
  font-family: "HK Grotesk SemiBold";
  border-radius: 10px;
`;

const TableStyled = styled.div`
  width: 100%;
  background: white;

  .percentage-diff {
    font-weight: 500;
    font-size: 1rem;
    font-family: "HK Grotesk Bold";
    opacity: 0.9;
  }
  .comparison-lower {
    color: ${PRICE_CHECK_THEME_COLORS.STATE.SUCCESS};
  }
  .comparison-higher {
    color: ${PRICE_CHECK_THEME_COLORS.STATE.FAIL};
  }
  .main-price {
    opacity: 0.5;
    font-size: 0.9rem;
  }
  .competitor-price {
    opacity: 1;
    font-size: 1.1rem;
  }
`;

const TableBodyStyled = styled.div`
  /* border-top: 1px solid #e5e7eb; */
`;

const TableRowStyled = styled.div`
  display: flex;
  padding: 12px 0;
  border-bottom: 1px solid #e5e7eb;
  column-gap: 0.5rem;
`;

const TableCellStyled = styled.div`
  flex: 1; /* Makes all cells take up equal space */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.remove-cell {
    max-width: 50px; /* Small fixed width for the remove button */
    justify-content: flex-end;
  }

  &.product-name-cell {
    justify-content: flex-start;
  }

  &.percentage-cell {
    max-width: 70px;
  }

  &.prices-info-container {
    /* display: flex;
    width: 100%; */
  }

  &.price-cell {
    justify-content: start;
  }
  .price-container {
    display: flex;
    flex-direction: column;
    font-family: "HK Grotesk SemiBold";
  }
`;

const RemoveButtonStyled = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #6b7280;
  &:hover {
    color: #ef4444;
  }
`;

const MainItemStyled = styled.div`
  font-size: 1rem;
  font-family: "HK Grotesk SemiBold";
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right; /* Align the text to the right */
`;

const CompetitorItemStyled = styled.div`
  color: #6b7280;
  font-size: 0.875rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
`;

interface FilterDrawerProps {
  visible: boolean;
  onClose: () => void;
}

const PriceCheckListDrawer: React.FC<FilterDrawerProps> = ({
  visible,
  onClose,
}) => {
  const onCloseEvent = () => {
    onClose();
  };
  const { selectedItems, removeSelectedItem } = usePriceCheckStore();
  const currency = "SEK";

  return (
    <DrawerStyled
      title={<TitleStyled>Valda artiklar</TitleStyled>}
      width={720}
      onClose={onCloseEvent}
      open={visible}
      extra={
        <Space>
          <Tooltip
            color={"gray"}
            placement="topRight"
            title={"Exportera/ladda ner som csv"}
          >
            <ButtonStyled
              size="middle"
              type="primary"
              icon={<Download size={"1em"} strokeWidth={"3px"} />}
              onClick={() => {
                console.log("clicked");
              }}
            >
              <span style={{ marginTop: "2px" }}>Exportera</span>
            </ButtonStyled>
          </Tooltip>
        </Space>
      }
    >
      <TableStyled>
        <TableBodyStyled>
          {Object.entries(selectedItems).map(([itemId, selection]) => {
            const mainPrice = 100
            // const mainPrice = selection?.mainItem?.item?.product?.mainItemNo;
            const mainItemName = "No name data"
            const competitorPrice = selection?.competitorItem?.price;
            const pricesAreValid = mainPrice && competitorPrice;
            const trackedDifferencePercentage = pricesAreValid ? calculatePercentageDiff(
              mainPrice,
              competitorPrice
            ) : null;
            const isTrackedPriceLower = pricesAreValid ? competitorPrice < mainPrice : false;

            return (
              <TableRowStyled key={itemId}>
                <TableCellStyled className="product-name-cell">
                  <MainItemStyled
                    title={`${selection?.mainItem?.item?.itemNo ?? ""} ${
                      mainItemName ?? ""
                    }`}
                  >
                    {selection?.mainItem?.item?.itemNo}{" "}
                    {mainItemName}
                  </MainItemStyled>
                </TableCellStyled>
                <TableCellStyled className="competitor-name-cell">
                  <CompetitorItemStyled
                    title={selection?.competitorItem?.companyName ?? ""}
                  >
                    {selection?.competitorItem?.companyName}
                  </CompetitorItemStyled>
                </TableCellStyled>
                <TableCellStyled className="price-cell">
                  <div className="price-container">
                    <span className="main-price">
                      {mainPrice ? `${mainPrice}`: "Inget orginal pris" }{" "}
                      <span className="currency"> {currency}</span>
                    </span>
                    <span className="competitor-price">
                      {selection?.competitorItem?.price}{" "}
                      <span className="currency"> {currency}</span>
                    </span>
                  </div>
                </TableCellStyled>
                <TableCellStyled className="percentage-cell">
                  <div className="percentage-container">
                    <span
                      className={`percentage-diff default-cursor ${
                        isTrackedPriceLower
                          ? "comparison-higher"
                          : "comparison-lower"
                      }`}
                    >
                           {typeof trackedDifferencePercentage === "number"  ? `${trackedDifferencePercentage}%` : ""}
                    </span>
                  </div>
                </TableCellStyled>

                <TableCellStyled className="remove-cell">
                  <RemoveButtonStyled
                    onClick={() => removeSelectedItem(Number(itemId))}
                  >
                    <XCircle />
                  </RemoveButtonStyled>
                </TableCellStyled>
              </TableRowStyled>
            );
          })}
        </TableBodyStyled>
      </TableStyled>
    </DrawerStyled>
  );
};

export default PriceCheckListDrawer;
