import React, { RefObject, useEffect } from "react";
import { Card, Space, Button, Tooltip } from "antd";
import { PriceTrackerItem, PriceTrackerItemDto } from "interfaces";
import {
  CalendarArrowDown,
  CircleMinus,
  CirclePlus,
  ExternalLink,
  RefreshCwOff,
  ShieldCheck,
} from "lucide-react";
import {
  calculatePercentageDiff,
  formatDateWithTime,
  formatISO8601DateString,
  formatDateToDDMMYYYY,
} from "utility/helper";

import useIsSticky from "components/hooks/ui/useIsSticky";
import PriceCheckStickyButtons from "components/priceCheck/toolBar/buttons/priceCheckStickyButtons";
import PriceCheckListDrawer from "components/priceCheck/drawers/selectedList/priceCheckListDrawer";
import usePriceCheckStore from "stores/priceCheckStore";

import { PRICE_CHECK_THEME_COLORS } from "utility/priceCheck/constants";
import {
  CardEntityContent,
  CompareEntitiesWrapper,
  MainEntityInformation,
  MainEntityPropsList,
  MainEntityWrapper,
  PriceCheckWrapper,
  PriceWidget,
  PriceMainWidget,
} from "components/priceCheck/styles/priceCheckStyles";
import ToolBarPriceCheck from "components/priceCheck";
import PriceCheckFullScreenLoader from "components/priceCheck/loaders/priceCheckFullScreenLoader";
import ControlledCheckBox from "components/common/checkBoxes/controlledCheckBox";
import { InfiniteScrollStatusDisplay } from "components/common/status/infiniteScrollStatusDisplay";
import usePriceCheckInfiniteScroll from "components/hooks/priceCheck/usePriceCheckInfiniteScroll";

// import { handleClassNameClick } from "components/priceCheck/helpers";

const STICKY_BUTTONS_ID = "sticky-buttons-wrapper";

const PriceCheckView: React.FC = () => {
  // const localeState = useLocaleStore();
  const isSticky = useIsSticky(STICKY_BUTTONS_ID, 16);
  const {
    selectedItems,
    toggleCompetitorItem,
    selectedListIsOpen,
    setSelectedListIsOpen,
    dataSource,
    selectedUpdateItems,
    toggleSelectedUpdateItem,
    resetState,
  } = usePriceCheckStore();

  const {
    infiniteItemsIsLoading,
    infiniteItemsLength,
    infiniteScrollError,
    hasMoreItems,
    totalPages,
    fetchMoreData,
    refetch,
    targetRef,
  } = usePriceCheckInfiniteScroll();
  const currency = "SEK";

  useEffect(() => {
    return () => {
      resetState();
    };
  }, [resetState]);

  return (
    <>
      <div id={"price-check-wrapper"} style={{ position: "relative" }}>
        <div>
          <ToolBarPriceCheck />
        </div>
        <PriceCheckWrapper>
          <PriceCheckStickyButtons
            formLoading={false}
            isInitalLoading={false}
            // saveButtonProps={}
            hideBtns={!isSticky}
            compId={STICKY_BUTTONS_ID}
          />
          <Space
            wrap
            direction="vertical"
            size={18}
            style={{ display: "flex" }}
          >
            <>
              {dataSource?.map((wrapperItem, index) => {
                const id = wrapperItem?.id;
                const {
                  itemNo,
                  priceTrackerItems,
                  latestLookup,
                  product,
                  prisjaktProductUrl,
                } = wrapperItem?.item;

                const mainProductIsSynced = product !== null;
                const mainProductName = "Inget namn"; //product?.name ??
                const mainProductSyncedPrice = undefined; //product?.price
                const mainProductPrice = mainProductSyncedPrice;
                const mainLatestLookupDate = formatDateToDDMMYYYY(latestLookup);
                const mainCompleteLatestLookupDate =
                  formatDateWithTime(latestLookup);

                const mainRedirectData = {
                  urlOrMessage:
                    prisjaktProductUrl && prisjaktProductUrl?.trim()
                      ? prisjaktProductUrl
                      : null,
                  validUrl: !!(
                    prisjaktProductUrl && prisjaktProductUrl?.trim()
                  ),
                };

                const handleCompetitorClick = (
                  competitorItem: PriceTrackerItemDto
                ) => {
                  toggleCompetitorItem(id, wrapperItem, competitorItem);
                };

                const handleUpdateItemClick = () => {
                  toggleSelectedUpdateItem(id, wrapperItem);
                };

                const isUpdateItemSelected =
                  selectedUpdateItems[id]?.productId === id;

                return (
                  <Card
                    style={{
                      backgroundColor: PRICE_CHECK_THEME_COLORS.DEFAULT_CARD_BG,
                    }}
                    bordered={false}
                    className="criclebox h-full"
                  >
                    <CardEntityContent>
                      <MainEntityWrapper>
                        <div className="main-entity-content">
                          <div className="main-entity-information-wrapper">
                            <MainEntityInformation>
                              <div className="main-entity-top-area-content">
                                <Tooltip
                                  color={PRICE_CHECK_THEME_COLORS.STATE.SUCCESS}
                                  title={"Lägg till i uppdateringslistan"}
                                  mouseEnterDelay={0.8}
                                  mouseLeaveDelay={0.0}
                                >
                                  <ControlledCheckBox
                                    name="generate-item"
                                    id={`checkbox-${id}`}
                                    checked={isUpdateItemSelected} // Control checked state
                                    onChange={handleUpdateItemClick} // Handle click event
                                  />
                                </Tooltip>
                                <span className="main-entity-title line-clamp-text">
                                  <span>{itemNo}</span>{" "}
                                  <span
                                    style={{
                                      color: `${PRICE_CHECK_THEME_COLORS.DISCREET_TEXT}`,
                                    }}
                                  >
                                    - {mainProductName}
                                  </span>
                                </span>
                              </div>

                              <div className="main-entity-grid-bottom-area-content">
                                <div className="main-entity-grid-area-content-image">
                                  <img
                                    className="product-image"
                                    alt="test"
                                    src="https://picsum.photos/200/300"
                                  />
                                </div>
                                <div className="main-entity-grid-area-content-information">
                                  <MainEntityPropsList>
                                    <li>
                                      <span className="prop-name">Name:</span>{" "}
                                      Smartphone X1"
                                    </li>
                                    <li>
                                      <span className="prop-name">
                                        Price History:
                                      </span>{" "}
                                      $499.99
                                    </li>
                                    <li>
                                      <span className="prop-name">
                                        Availability:
                                      </span>{" "}
                                      In stock
                                    </li>
                                  </MainEntityPropsList>
                                </div>
                              </div>
                            </MainEntityInformation>
                          </div>
                          <div className="main-entity-price-widget-wrapper">
                            <PriceMainWidget
                              className="main-widget"
                              style={{
                                backgroundColor:
                                  PRICE_CHECK_THEME_COLORS.DEFAULT_CARD_BG,
                              }}
                            >
                              <div className="main-widget-content">
                                <ul className="main-widget-list">
                                  <li>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontSize: "1.3rem",
                                          fontFamily: "HK Grotesk Bold",
                                        }}
                                      >
                                        {mainProductPrice ? (
                                          <>
                                            {" "}
                                            <span
                                              style={{
                                                color:
                                                  PRICE_CHECK_THEME_COLORS.PRIMARY_TEXT,
                                              }}
                                            >
                                              {mainProductPrice}{" "}
                                            </span>{" "}
                                            <span className="currency">
                                              {currency}{" "}
                                            </span>
                                          </>
                                        ) : (
                                          <span
                                            style={{
                                              opacity: "0.2",
                                              textDecorationLine:
                                                "line-through",
                                            }}
                                          >
                                            Inget pris
                                          </span>
                                        )}
                                      </div>{" "}
                                      <Tooltip
                                        color={
                                          mainProductIsSynced
                                            ? PRICE_CHECK_THEME_COLORS.STATE
                                                .SUCCESS
                                            : PRICE_CHECK_THEME_COLORS.STATE
                                                .FAIL
                                        }
                                        title={
                                          mainProductIsSynced
                                            ? "Produkt är synkad"
                                            : "Produkten är inte synkad"
                                        }
                                      >
                                        <span
                                          className={`main-percentage-diff ${
                                            mainProductIsSynced
                                              ? "success-state"
                                              : "fail-state"
                                          }`}
                                        >
                                          {mainProductIsSynced ? (
                                            <ShieldCheck />
                                          ) : (
                                            <RefreshCwOff />
                                          )}
                                        </span>
                                      </Tooltip>
                                    </div>
                                  </li>
                                </ul>
                                <li>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "flex-end",
                                    }}
                                  >
                                    {/* const mainCompleteLatestLookupDate = formatDateWithTime(latestLookup); */}
                                    <Tooltip
                                      mouseEnterDelay={0.8}
                                      mouseLeaveDelay={0.0}
                                      color={
                                        mainRedirectData?.validUrl
                                          ? PRICE_CHECK_THEME_COLORS.PRIMARY
                                          : PRICE_CHECK_THEME_COLORS.DEFAULT_TOOLTIP
                                      }
                                      title={mainCompleteLatestLookupDate}
                                    >
                                      {" "}
                                      <span
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          columnGap: "0.2em",
                                          fontSize: "1rem",
                                        }}
                                        className="date"
                                      >
                                        <CalendarArrowDown
                                          size={"1em"}
                                          style={{ marginTop: "-0.2em" }}
                                        />
                                        <span>{mainLatestLookupDate}</span>
                                      </span>
                                    </Tooltip>

                                    <Tooltip
                                      mouseEnterDelay={0.8}
                                      mouseLeaveDelay={0.0}
                                      color={
                                        mainRedirectData?.validUrl
                                          ? PRICE_CHECK_THEME_COLORS.PRIMARY
                                          : PRICE_CHECK_THEME_COLORS.DEFAULT_TOOLTIP
                                      }
                                      title={
                                        mainRedirectData?.urlOrMessage ? (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontFamily: "HK Grotesk Bold",
                                              }}
                                            >
                                              Gå till URL:
                                            </span>
                                            <span>
                                              {mainRedirectData?.urlOrMessage}
                                            </span>
                                          </div>
                                        ) : (
                                          "Ingen url"
                                        )
                                      }
                                    >
                                      <Button
                                        type="link"
                                        disabled={!mainRedirectData?.validUrl}
                                        className="icon-button-discreet"
                                        onClick={() => {
                                          if (mainRedirectData?.validUrl) {
                                            window.open(
                                              mainRedirectData?.urlOrMessage ??
                                                "",
                                              "_blank"
                                            );
                                          }
                                        }}
                                      >
                                        <span className="icon-container">
                                          <ExternalLink
                                            strokeWidth={2}
                                            className={`${
                                              mainRedirectData?.validUrl
                                                ? "default-link-state"
                                                : "fail-link-state"
                                            }`}
                                          />
                                        </span>
                                      </Button>
                                    </Tooltip>
                                  </div>
                                </li>
                              </div>
                            </PriceMainWidget>
                          </div>
                        </div>
                      </MainEntityWrapper>

                      <CompareEntitiesWrapper id={id?.toString()}>
                        {priceTrackerItems && priceTrackerItems.length > 0 ? (
                          <div className="compare-entities-content">
                            {priceTrackerItems?.map((trackedItem, index) => {
                              const {
                                price: trackedPrice,
                                companyName: trackedCompanyName,
                              } = trackedItem;
                              const trackedLatestLookup =
                                wrapperItem?.item?.latestLookup;
                              const trackedLatestLookupDate =
                                formatDateToDDMMYYYY(trackedLatestLookup) ??
                                "Ingen datum";
                              const trackedDifferencePercentage =
                                mainProductPrice
                                  ? calculatePercentageDiff(
                                      mainProductPrice,
                                      trackedPrice
                                    )
                                  : null;
                              const isTrackedPriceLower = mainProductPrice
                                ? trackedPrice < mainProductPrice
                                : false;

                              const isCompetitorItemSelected =
                                selectedItems[id]?.competitorItem?.id ===
                                trackedItem.id;

                              const trackedRedirectData = mainRedirectData;

                              return (
                                <PriceWidget
                                  key={
                                    trackedItem?.id + trackedItem?.companyName
                                  }
                                  // onClick={(event) =>
                                  //   handleClassNameClick(
                                  //     () => handleCompetitorClick(trackedItem),
                                  //     event,
                                  //     "price-widget-list"
                                  //   )
                                  // }
                                  className={`competitor-item ${
                                    isCompetitorItemSelected
                                      ? "price-widget-selected"
                                      : ""
                                  }`}
                                >
                                  <div className="price-widget-content">
                                    <ul className="price-widget-list">
                                      <li>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "start",
                                            paddingBottom: "0.5em",
                                          }}
                                        >
                                          <span className="compare-entity-title">
                                            {trackedCompanyName}
                                          </span>

                                          <Tooltip
                                            mouseEnterDelay={0.8}
                                            mouseLeaveDelay={0.0}
                                            // color={}
                                            title={`${
                                              isCompetitorItemSelected
                                                ? `Avmarkera ${trackedCompanyName}`
                                                : `Markera${trackedCompanyName}`
                                            } `}
                                          >
                                            {isCompetitorItemSelected ? (
                                              <CircleMinus
                                                className="icon-button"
                                                size={"1.6rem"}
                                                strokeWidth={2}
                                                onClick={() =>
                                                  handleCompetitorClick(
                                                    trackedItem
                                                  )
                                                }
                                              />
                                            ) : (
                                              <CirclePlus
                                                size={"1.6rem"}
                                                strokeWidth={2}
                                                className="icon-button"
                                                onClick={() =>
                                                  handleCompetitorClick(
                                                    trackedItem
                                                  )
                                                }
                                              />
                                            )}
                                          </Tooltip>
                                        </div>
                                      </li>

                                      <li>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <span className="comparison-price default-cursor">
                                            <span>{trackedPrice}</span>{" "}
                                            <span className="currency">
                                              {currency}{" "}
                                            </span>
                                          </span>{" "}
                                          <span
                                            className={`percentage-diff default-cursor ${
                                              isTrackedPriceLower
                                                ? "comparison-higher"
                                                : "comparison-lower"
                                            }`}
                                          >
                                            {typeof trackedDifferencePercentage ===
                                            "number"
                                              ? `${trackedDifferencePercentage}%`
                                              : ""}
                                          </span>
                                        </div>
                                      </li>
                                    </ul>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "flex-end",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          columnGap: "0.2em",
                                          fontSize: "0.8rem",
                                        }}
                                        className="date"
                                      >
                                        <CalendarArrowDown
                                          size={"1em"}
                                          style={{ marginTop: "-0.1em" }}
                                        />
                                        <span>{trackedLatestLookupDate}</span>
                                      </span>

                                      <Tooltip
                                        mouseEnterDelay={0.8}
                                        mouseLeaveDelay={0.0}
                                        color={
                                          trackedRedirectData?.validUrl
                                            ? PRICE_CHECK_THEME_COLORS.PRIMARY
                                            : PRICE_CHECK_THEME_COLORS.DEFAULT_TOOLTIP
                                        }
                                        title={
                                          trackedRedirectData?.urlOrMessage ? (
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontFamily: "HK Grotesk Bold",
                                                }}
                                              >
                                                Gå till URL:
                                              </span>
                                              <span>
                                                {
                                                  trackedRedirectData?.urlOrMessage
                                                }
                                              </span>
                                            </div>
                                          ) : (
                                            "Ingen url"
                                          )
                                        }
                                      >
                                        <Button
                                          type="link"
                                          disabled={
                                            !trackedRedirectData?.validUrl
                                          }
                                          className="icon-button-discreet"
                                          onClick={() => {
                                            if (trackedRedirectData?.validUrl) {
                                              window.open(
                                                trackedRedirectData.urlOrMessage ??
                                                  "",
                                                "_blank"
                                              );
                                            }
                                          }}
                                        >
                                          <span className="icon-container">
                                            <ExternalLink
                                              size={"1.1rem"}
                                              strokeWidth={2}
                                              className={`${
                                                trackedRedirectData?.validUrl
                                                  ? "default-link-state"
                                                  : "fail-link-state"
                                              }`}
                                            />
                                          </span>
                                        </Button>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </PriceWidget>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="empty-compare-entities-content">
                            <span>Inga prisjämförelser tillgängliga</span>
                          </div>
                        )}
                      </CompareEntitiesWrapper>
                    </CardEntityContent>
                  </Card>
                );
              })}
            </>
            {/* <div ref={targetRef}></div> */}
            <InfiniteScrollStatusDisplay
              isLoading={infiniteItemsIsLoading}
              itemsLength={infiniteItemsLength}
              error={infiniteScrollError}
              hasMoreItems={hasMoreItems}
              fetchMoreData={fetchMoreData}
              refetch={refetch}
              targetRef={targetRef}
              totalPages={totalPages} // Assuming page size is 10
            />
          </Space>
        </PriceCheckWrapper>
        {/* <div ref={targetRef}></div> */}
      </div>
      <PriceCheckListDrawer
        visible={selectedListIsOpen}
        onClose={() => setSelectedListIsOpen(false)}
      />
      <PriceCheckFullScreenLoader />
      {/* Modal for reporting post or update requests */}
      {/* <ReportModal /> */}
    </>
  );
};

export default PriceCheckView;

// interface ScrollProps {
//   dataSourceIsLoading: boolean;
//   dataSourceError: any;
//   dataSource: any[];
//   hasMoreItems: boolean;
//   targetRef: React.RefObject<HTMLDivElement>;
//   fetchMoreData: () => void;
//   refetch: () => void;
// }

// const TestRef = ({ scrollProps }: { scrollProps: ScrollProps }) => {
//   const {
//     dataSourceIsLoading,
//     dataSourceError,
//     dataSource,
//     hasMoreItems,
//     targetRef,
//     fetchMoreData,
//     refetch,
//   } = scrollProps;

//   return (
//     <div>
//       <div ref={targetRef}>
//         {dataSourceIsLoading && <div>Loading...</div>}
//         {!dataSourceIsLoading && dataSourceError && <div>Error: {dataSourceError}</div>}
//         {!dataSourceIsLoading && !dataSourceError && dataSource.length === 0 && <div>No data available</div>}
//         {hasMoreItems && <button onClick={fetchMoreData}>Load More</button>}
//       </div>
//     </div>
//   );
// };

// const TestRef = ({ targetRef }: { targetRef: RefObject<HTMLDivElement> }) => {
//   return <div ref={targetRef}>Content to observe...</div>;
// };

// // This will work fine
// // Hook implemented in the parent component
// const { infiniteScrollUiElements, targetRef } = usePriceCheckInfiniteScroll();
// <TestRef targetRef={targetRef} />
// // Comp itself
// const TestRef = ({ targetRef }: { targetRef: RefObject<HTMLDivElement> }) => {
//   return <div ref={targetRef}></div>;
// };

// // This will not work, infinte scroll with sam data will be rendered multiple times
// // Comp implemented in the parent component
// <TestRef targetRef={targetRef} />
// // Comp itself using the hook in there
// const TestRef = () => {
//   const { infiniteScrollUiElements, targetRef } = usePriceCheckInfiniteScroll();
//   return <div ref={targetRef}></div>;
// };
