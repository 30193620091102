import { useEffect, useRef } from "react";
import { useLocaleStore } from "stores/localeStore";
import BaseHttpClient from "./baseHttpClient";
import { getUserInfoFromCache } from "../../utility/helper";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export function useBaseHttpClient() {
  const apiClientRef = useRef<BaseHttpClient | null>(null);
  const localeState = useLocaleStore();

  useEffect(() => {

    if(!API_BASE_URL){
        console.error("API_BASE_URL is not defined in .env file");
        return;
    }

    if (!apiClientRef.current) {
      apiClientRef.current = new BaseHttpClient(API_BASE_URL);
      
      // Set token retriever
      apiClientRef.current.setTokenRetriever(() => {
        const userInfo = getUserInfoFromCache();
        return userInfo ? userInfo.token : null;
      });
    }

    // Set or update language retriever
    //apiClientRef.current.setLanguageRetriever(() => localeState.currentLanguage);

  }, [localeState.locale]);

  return apiClientRef.current;
}