import { useState } from 'react';
import { useBaseHttpClient } from './base/useBaseHttpClient';
import { GetManyRequestOptions, MetadataResponseWrapper, ResponseWrapper, ResponseWrapperMany, SortOrderEnum } from 'interfaces/baseTypes';

const usePriceTrackerApiClient = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const apiClient = useBaseHttpClient();
    
    async function getPriceTrackerById<T>(id: number): Promise<ResponseWrapper<T> | undefined>
    {
        setLoading(true);
        setError(null);
        try {
            return await apiClient?.get<ResponseWrapper<T>>(`/pricetracker/${id}`);
        } catch (e: any) {
            setError(e.message || 'An error occurred');
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    async function getPriceTrackers<T>(options: GetManyRequestOptions = {}): Promise<ResponseWrapperMany<T> | undefined>
    {
        const { page = 0, pageSize = 10, order, orderByField } = options;
        setLoading(true);
        setError(null);
        try {
            return await apiClient?.get<ResponseWrapperMany<T>>('/pricetracker', { page: page ?? 0, pageSize: pageSize ?? 10, order: order ?? SortOrderEnum.DESC, orderByField: orderByField ?? "createdAt" });
        } catch (e: any) {
            setError(e.message || 'An error occurred');
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    async function priceTrackerBatchImport<T>(articleNumbers: string[], searchKeys: string[]): Promise<void>
    {
        setLoading(true);
        setError(null);
        try {
            await apiClient?.post<ResponseWrapper<T>>('/pricetracker/batch-import', { articleNumbers, searchKeys });
        } catch (e: any) {
            setError(e.message || 'An error occurred');
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    async function priceTrackerBatchSynchronize<T>(ids: number[]): Promise<void>
    {
        setLoading(true);
        setError(null);
        try {
            await apiClient?.putWithoutBody<ResponseWrapper<T>>('/pricetracker/batch-import', { priceTrackerIds: ids });
        } catch (e: any) {
            setError(e.message || 'An error occurred');
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    async function getMetadata(): Promise<MetadataResponseWrapper | undefined>
    {
        setLoading(true);
        setError(null);
        try {
            return await apiClient?.get<MetadataResponseWrapper>('/pricetracker/metadata');
        } catch (e: any) {
            setError(e.message || 'An error occurred');
            return undefined;
        } finally {
            setLoading(false);
        }
    }


    return { getPriceTrackerById, getPriceTrackers, priceTrackerBatchImport, priceTrackerBatchSynchronize, getMetadata, loading, error };
};

export default usePriceTrackerApiClient;