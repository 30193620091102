import { BaseKey } from "@refinedev/core";
import { priceCheckMockData } from "components/priceCheck/mockData.js/mock";
import { PriceCheckItem, PriceTrackerDto, PriceTrackerItemDto } from "interfaces";
import { MetadataResponseWrapper, ResponseWrapperMany, BaseItem } from "interfaces/baseTypes";
import { create } from "zustand";


// export type PriceTrackerItem = {
//   id: number;
//   createdAt: string;
//   modifiedAt: string;
//   item: PriceTrackerDto;
// }

type SelectedItem = {
  mainItem: BaseItem<PriceTrackerDto>;
  competitorItem: PriceTrackerItemDto | undefined;
};

type SelectedUpdateItem = {
  productId: BaseKey;
  itemNo: string;
};

type PriceCheckStore = {
  currentQuery: string | undefined;
  setCurrentQuery: (query: string) => void;
  selectedListIsOpen: boolean;
  setSelectedListIsOpen: (isOpen: boolean) => void;
  toggleSelectedListIsOpen: () => void;

  dataSource: BaseItem<PriceTrackerDto>[];
  addDataToDataSource: (data: BaseItem<PriceTrackerDto>[]) => void;
  setDataSource: (data: BaseItem<PriceTrackerDto>[]) => void;

  setDataSourceIsLoading: (isLoading: boolean) => void;
  dataSourceIsLoading: boolean;
  dataSourceError: { isError: boolean; errorMessage: string; error?: any };
  setDataSourceError: (error: {
    isError: boolean;
    errorMessage: string;
    error?: any;
  }) => void;
  // DataSource update
  updateDataSourceIsLoading: boolean;
  setUpdateDataSourceIsLoading: (isLoading: boolean) => void;
  updateDataSourceError: {
    isError: boolean;
    errorMessage: string;
    error?: any;
  };
  setUpdateDataSourceError: (error: {
    isError: boolean;
    errorMessage: string;
    error?: any;
  }) => void;


  setSelecedItems: (selectedItems: Record<BaseKey, SelectedItem>) => void;
  selectedItems: Record<BaseKey, SelectedItem>;

  selectedUpdateItems: Record<BaseKey, SelectedUpdateItem>;
  addSelectedItem: (
    itemId: BaseKey,
    mainItem: BaseItem<PriceTrackerDto>,
    competitorItem: PriceTrackerItemDto
  ) => void;
  setSelectedUpdateItems: (
    selectedUpdateItems: Record<BaseKey, SelectedUpdateItem>
  ) => void;


  addSelectedUpdateItem: (itemId: BaseKey, mainItem: BaseItem<PriceTrackerDto>) => void;
  removeSelectedUpdateItem: (itemId: BaseKey) => void;


  updateCompetitorItem: (
    itemId: BaseKey,
    competitorItem: PriceTrackerItemDto
  ) => void;
  removeSelectedItem: (itemId: BaseKey) => void;
  toggleCompetitorItem: (
    itemId: BaseKey,
    mainItem: BaseItem<PriceTrackerDto>,
    competitorItem: PriceTrackerItemDto
  ) => void;
  toggleSelectedUpdateItem: (itemId: BaseKey, mainItem: BaseItem<PriceTrackerDto>) => void;
  resetState: (options?: { keepCurrentQuery?: boolean }) => void; // Updated to accept an options object
};

// Initial state definition
const initialState = {
  dataSource: [],
  dataSource1: [],
  dataSourceIsLoading: false,
  dataSourceError: { isError: false, errorMessage: "", error: null },
  updateDataSourceIsLoading: false,
  updateDataSourceError: { isError: false, errorMessage: "", error: null },
  selectedItems: {},
  selectedUpdateItems: {},
  selectedListIsOpen: false,
  currentQuery: "",
};

const usePriceCheckStore = create<PriceCheckStore>((set, get) => ({
  ...initialState, // Spread the initial state

  setDataSourceIsLoading: (isLoading) =>
    set({ dataSourceIsLoading: isLoading }),

  setDataSourceError: ({ isError, errorMessage, error }) =>
    set({ dataSourceError: { isError, errorMessage, error } }),
  // DataSource update

  setUpdateDataSourceIsLoading: (isLoading) =>
    set({ updateDataSourceIsLoading: isLoading }),

  setUpdateDataSourceError: ({ isError, errorMessage, error }) =>
    set({ updateDataSourceError: { isError, errorMessage, error } }),

  setCurrentQuery: (query: string | undefined) => {
    set(() => ({
      currentQuery: query, // Update the query in the store
    }));
  },

  // Actions
  setDataSource: (newData) => {
    set(() => ({
      dataSource: newData, // Directly replace the current dataSource with newData
    }));
  },

  addDataToDataSource: (newData) => {
    set((state) => {
      const { dataSource } = state;
      const updatedDataSource = [...dataSource, ...newData];
      return {
        dataSource: updatedDataSource,
      };
    });
  },

  setSelectedUpdateItems: (selectedUpdateItems) => set({ selectedUpdateItems }),

  addSelectedUpdateItem: (itemId, mainItem) =>
    set((state) => ({
      selectedUpdateItems: {
        ...state.selectedUpdateItems,
        [itemId]: {
          productId: mainItem.id,
          itemNo: mainItem?.item?.itemNo,
        },
      },
    })),

  removeSelectedUpdateItem: (itemId) =>
    set((state) => {
      const newSelectedUpdateItems = { ...state.selectedUpdateItems };
      delete newSelectedUpdateItems[itemId];
      return { selectedUpdateItems: newSelectedUpdateItems };
    }),

  setSelecedItems: (selectedItems) => set({ selectedItems }),

  addSelectedItem: (itemId, mainItem, competitorItem) =>
    set((state) => ({
      selectedItems: {
        ...state.selectedItems,
        [itemId]: { mainItem, competitorItem },
      },
    })),

  removeSelectedItem: (itemId) =>
    set((state) => {
      const newSelectedItems = { ...state.selectedItems };
      delete newSelectedItems[itemId];
      return { selectedItems: newSelectedItems };
    }),

  updateCompetitorItem: (itemId, competitorItem) =>
    set((state) => {
      if (state.selectedItems[itemId]) {
        return {
          selectedItems: {
            ...state.selectedItems,
            [itemId]: {
              ...state.selectedItems[itemId],
              competitorItem,
            },
          },
        };
      } else {
        console.warn(`Item with ID ${itemId} is not selected.`);
        return {}; // No state change
      }
    }),

  toggleCompetitorItem: (itemId, mainItem, competitorItem) =>
    set((state) => {
      const existingSelectedItem = state.selectedItems[itemId];
      if (existingSelectedItem) {
        if (existingSelectedItem?.competitorItem?.id === competitorItem.id) {
          const newSelectedItems = { ...state.selectedItems };
          delete newSelectedItems[itemId];
          return { selectedItems: newSelectedItems };
        } else {
          return {
            selectedItems: {
              ...state.selectedItems,
              [itemId]: {
                ...existingSelectedItem,
                competitorItem,
              },
            },
          };
        }
      } else {
        return {
          selectedItems: {
            ...state.selectedItems,
            [itemId]: { mainItem, competitorItem },
          },
        };
      }
    }),

  toggleSelectedUpdateItem: (itemId, mainItem) =>
    set((state) => {
      const existingSelectedUpdateItem = state.selectedUpdateItems[itemId];
      if (existingSelectedUpdateItem) {
        const newSelectedUpdateItems = { ...state.selectedUpdateItems };
        delete newSelectedUpdateItems[itemId];
        return { selectedUpdateItems: newSelectedUpdateItems };
      } else {
        return {
          selectedUpdateItems: {
            ...state.selectedUpdateItems,
            [itemId]: {
              productId: mainItem.id,
              itemNo: mainItem.item?.itemNo,
            },
          },
        };
      }
    }),

  setSelectedListIsOpen: (isOpen) => set({ selectedListIsOpen: isOpen }),
  toggleSelectedListIsOpen: () =>
    set((state) => ({ selectedListIsOpen: !state.selectedListIsOpen })),

  // Reset state to the initial state
  resetState: ({
    keepCurrentQuery = false,
  }: { keepCurrentQuery?: boolean } = {}) =>
    set((state) => ({
      ...initialState,
      currentQuery: keepCurrentQuery ? state.currentQuery : undefined, // Conditionally keep or reset currentQuery
    })),
}));

export default usePriceCheckStore;

// import { priceCheckMockData } from "components/priceCheck/mockData.js/mock";
// import { PriceCheckItem, PriceTrackerItem } from "interfaces";
// import { create } from "zustand";

// type SelectedItem = {
//   mainItem: PriceCheckItem;
//   competitorItem: PriceTrackerItem | undefined;
// };

// type SelectedUpdateItem = {
//   productId: number;
//   itemNo: string;
// };

// type PriceCheckStore = {
//   selectedListIsOpen: boolean;
//   setSelectedListIsOpen: (isOpen: boolean) => void;
//   toggleSelectedListIsOpen: () => void;
//   dataSource: PriceCheckItem[];
//   setDataSource: (data: PriceCheckItem[]) => void;
//   selectedItems: Record<number, SelectedItem>;
//   selectedUpdateItems: Record<number, SelectedUpdateItem>;
//   addSelectedItem: (
//     itemId: number,
//     mainItem: PriceCheckItem,
//     competitorItem: PriceTrackerItem
//   ) => void;
//   addSelectedUpdateItem: (itemId: number, mainItem: PriceCheckItem) => void;
//   removeSelectedUpdateItem: (itemId: number) => void;
//   updateCompetitorItem: (
//     itemId: number,
//     competitorItem: PriceTrackerItem
//   ) => void;
//   removeSelectedItem: (itemId: number) => void;
//   toggleCompetitorItem: (
//     itemId: number,
//     mainItem: PriceCheckItem,
//     competitorItem: PriceTrackerItem
//   ) => void;
//   toggleSelectedUpdateItem: (itemId: number, mainItem: PriceCheckItem) => void;
// };

// const usePriceCheckStore = create<PriceCheckStore>((set, get) => ({
//   // Initial state
//   dataSource: [],
//   // dataSource: [...priceCheckMockData],
//   selectedItems: {},
//   selectedUpdateItems: {},

//   // Actions
//   // Append new data to the existing dataSource
//   setDataSource: (newData) => {
//     const { dataSource, selectedItems, selectedUpdateItems } = get();
//     const updatedSelectedItems: Record<number, SelectedItem> = {
//       ...selectedItems,
//     };
//     const updatedSelectedUpdateItems: Record<number, SelectedUpdateItem> = {
//       ...selectedUpdateItems,
//     };

//     // Append the new data to the current dataSource
//     const updatedDataSource = [...dataSource, ...newData];

//     // Update selectedItems and selectedUpdateItems based on the new data
//     newData.forEach((item) => {
//       const selectedItem = selectedItems[item.productId];

//       if (selectedItem) {
//         const updatedCompetitorItem = item?.priceTrackerItems?.find(
//           (competitor) => competitor?.id === selectedItem?.competitorItem?.id
//         );

//         if (updatedCompetitorItem) {
//           updatedSelectedItems[item.productId] = {
//             mainItem: item,
//             competitorItem: updatedCompetitorItem,
//           };
//         } else {
//           updatedSelectedItems[item.productId] = {
//             mainItem: item,
//             competitorItem: undefined,
//           };
//         }
//       }

//       if (selectedUpdateItems[item.productId]) {
//         updatedSelectedUpdateItems[item.productId] = {
//           productId: item.productId,
//           itemNo: item.itemNo,
//         };
//       }
//     });

//     // Set the updated dataSource (append) and selected items
//     set({
//       dataSource: updatedDataSource,
//       selectedItems: updatedSelectedItems,
//       selectedUpdateItems: updatedSelectedUpdateItems,
//     });
//   },

//   addSelectedItem: (itemId, mainItem, competitorItem) =>
//     set((state) => ({
//       selectedItems: {
//         ...state.selectedItems,
//         [itemId]: { mainItem, competitorItem },
//       },
//     })),

//   addSelectedUpdateItem: (itemId, mainItem) =>
//     set((state) => ({
//       selectedUpdateItems: {
//         ...state.selectedUpdateItems,
//         [itemId]: {
//           productId: mainItem.productId,
//           itemNo: mainItem.itemNo,
//         },
//       },
//     })),

//   removeSelectedUpdateItem: (itemId) =>
//     set((state) => {
//       const newSelectedUpdateItems = { ...state.selectedUpdateItems };
//       delete newSelectedUpdateItems[itemId];
//       return { selectedUpdateItems: newSelectedUpdateItems };
//     }),

//   removeSelectedItem: (itemId) =>
//     set((state) => {
//       const newSelectedItems = { ...state.selectedItems };
//       delete newSelectedItems[itemId];
//       return { selectedItems: newSelectedItems };
//     }),

//   updateCompetitorItem: (itemId, competitorItem) =>
//     set((state) => {
//       if (state.selectedItems[itemId]) {
//         return {
//           selectedItems: {
//             ...state.selectedItems,
//             [itemId]: {
//               ...state.selectedItems[itemId],
//               competitorItem,
//             },
//           },
//         };
//       } else {
//         console.warn(`Item with ID ${itemId} is not selected.`);
//         return {}; // No state change
//       }
//     }),

//   toggleCompetitorItem: (itemId, mainItem, competitorItem) =>
//     set((state) => {
//       const existingSelectedItem = state.selectedItems[itemId];
//       if (existingSelectedItem) {
//         if (existingSelectedItem?.competitorItem?.id === competitorItem.id) {
//           const newSelectedItems = { ...state.selectedItems };
//           delete newSelectedItems[itemId];
//           return { selectedItems: newSelectedItems };
//         } else {
//           return {
//             selectedItems: {
//               ...state.selectedItems,
//               [itemId]: {
//                 ...existingSelectedItem,
//                 competitorItem,
//               },
//             },
//           };
//         }
//       } else {
//         return {
//           selectedItems: {
//             ...state.selectedItems,
//             [itemId]: { mainItem, competitorItem },
//           },
//         };
//       }
//     }),

//   // Toggle function for selectedUpdateItems
//   toggleSelectedUpdateItem: (itemId, mainItem) =>
//     set((state) => {
//       const existingSelectedUpdateItem = state.selectedUpdateItems[itemId];
//       if (existingSelectedUpdateItem) {
//         // If the item exists in the selectedUpdateItems, remove it
//         const newSelectedUpdateItems = { ...state.selectedUpdateItems };
//         delete newSelectedUpdateItems[itemId];
//         return { selectedUpdateItems: newSelectedUpdateItems };
//       } else {
//         // Otherwise, add it to selectedUpdateItems
//         return {
//           selectedUpdateItems: {
//             ...state.selectedUpdateItems,
//             [itemId]: {
//               productId: mainItem.productId,
//               itemNo: mainItem.itemNo,
//             },
//           },
//         };
//       }
//     }),

//   selectedListIsOpen: false,
//   setSelectedListIsOpen: (isOpen) => set({ selectedListIsOpen: isOpen }),
//   toggleSelectedListIsOpen: () =>
//     set((state) => ({ selectedListIsOpen: !state.selectedListIsOpen })),
// }));

// export default usePriceCheckStore;

// import { priceCheckMockData } from "components/priceCheck/mockData.js/mock";
// import { PriceCheckItem, PriceTrackerItem } from "interfaces";
// import { create } from "zustand";

// type SelectedItem = {
//   mainItem: PriceCheckItem;
//   competitorItem: PriceTrackerItem | undefined;
// };

// type SelectedUpdateItem = {
//   mainItem: PriceCheckItem;
// };

// type PriceCheckStore = {
//   selectedListIsOpen: boolean;
//   setSelectedListIsOpen: (isOpen: boolean) => void;
//   toggleSelectedListIsOpen: () => void;
//   dataSource: PriceCheckItem[];
//   setDataSource: (data: PriceCheckItem[]) => void;
//   selectedItems: Record<number, SelectedItem>;
//   selectedUpdateItems: Record<number, SelectedUpdateItem>;
//   addSelectedItem: (
//     itemId: number,
//     mainItem: PriceCheckItem,
//     competitorItem: PriceTrackerItem
//   ) => void;
//   updateCompetitorItem: (
//     itemId: number,
//     competitorItem: PriceTrackerItem
//   ) => void;
//   removeSelectedItem: (itemId: number) => void;
//   toggleCompetitorItem: (
//     itemId: number,
//     mainItem: PriceCheckItem,
//     competitorItem: PriceTrackerItem
//   ) => void;
// };

// const usePriceCheckStore = create<PriceCheckStore>((set, get) => ({
//   // Initial state
//   dataSource: [...priceCheckMockData],
//   selectedItems: {},
//   selectedUpdateItems: {},

//   // Actions
//   setDataSource: (data) => {
//     // Get current selected items
//     const { selectedItems } = get();
//     const updatedSelectedItems: Record<number, SelectedItem> = { ...selectedItems };

//     // Iterate through the new data source
//     data.forEach((item) => {
//       const selectedItem = selectedItems[item.productId];

//       if (selectedItem) {
//         // Check if the corresponding competitor item still exists in the new data
//         const updatedCompetitorItem = item?.priceTrackerItems?.find(
//           (competitor) => competitor?.id === selectedItem?.competitorItem?.id
//         );

//         // If competitor item exists, update it, otherwise remove the competitorItem
//         if (updatedCompetitorItem) {
//           updatedSelectedItems[item.productId] = {
//             mainItem: item, // Update mainItem with new data
//             competitorItem: updatedCompetitorItem, // Update competitorItem
//           };
//         } else {
//           // If no competitor item found, remove it from selected items
//           updatedSelectedItems[item.productId] = {
//             mainItem: item, // Keep the mainItem updated
//             competitorItem: undefined, // Remove the competitorItem if it's no longer available
//           };
//         }
//       }
//     });

//     // Set the new data source and updated selected items
//     set({ dataSource: data, selectedItems: updatedSelectedItems });
//   },

//   addSelectedItem: (itemId, mainItem, competitorItem) =>
//     set((state) => ({
//       selectedItems: {
//         ...state.selectedItems,
//         [itemId]: { mainItem, competitorItem },
//       },
//     })),

//   removeSelectedItem: (itemId) =>
//     set((state) => {
//       const newSelectedItems = { ...state.selectedItems };
//       delete newSelectedItems[itemId];
//       return { selectedItems: newSelectedItems };
//     }),

//   updateCompetitorItem: (itemId, competitorItem) =>
//     set((state) => {
//       if (state.selectedItems[itemId]) {
//         return {
//           selectedItems: {
//             ...state.selectedItems,
//             [itemId]: {
//               ...state.selectedItems[itemId],
//               competitorItem,
//             },
//           },
//         };
//       } else {
//         console.warn(`Item with ID ${itemId} is not selected.`);
//         return {}; // No state change
//       }
//     }),

//   toggleCompetitorItem: (itemId, mainItem, competitorItem) =>
//     set((state) => {
//       const existingSelectedItem = state.selectedItems[itemId];
//       if (existingSelectedItem) {
//         if (existingSelectedItem?.competitorItem?.id === competitorItem.id) {
//           // Competitor item is already selected; deselect it
//           const newSelectedItems = { ...state.selectedItems };
//           delete newSelectedItems[itemId];
//           return { selectedItems: newSelectedItems };
//         } else {
//           // Different competitor item selected; update competitor item
//           return {
//             selectedItems: {
//               ...state.selectedItems,
//               [itemId]: {
//                 ...existingSelectedItem,
//                 competitorItem,
//               },
//             },
//           };
//         }
//       } else {
//         // No selection exists; add new selected item
//         return {
//           selectedItems: {
//             ...state.selectedItems,
//             [itemId]: { mainItem, competitorItem },
//           },
//         };
//       }
//     }),

//   selectedListIsOpen: false,
//   setSelectedListIsOpen: (isOpen) => set({ selectedListIsOpen: isOpen }),
//   toggleSelectedListIsOpen: () =>
//     set((state) => ({ selectedListIsOpen: !state.selectedListIsOpen })),
// }));

// export default usePriceCheckStore;
