import { BaseKey, BaseOption } from "@refinedev/core";
import SearchableSelect from "components/common/select/SearchableSelect";
import { useEffect, useState } from "react";
import usePriceCheckStore from "stores/priceCheckStore";

interface SelectGroupProps {
  isDisabled?: boolean | undefined;
  isOpen?: boolean | undefined;
}
// type FlexibleOption = BaseOption & {
//   [key: string]: any;
// };

const PriceCheckSelectQuery = ({ isDisabled, isOpen }: SelectGroupProps) => {
  const { currentQuery, setCurrentQuery, dataSourceIsLoading  } = usePriceCheckStore(); // Zustand store for query management
  const [options, setOptions] = useState<{ value: BaseKey; label: string }[]>([]); // Options for the select
  const [isLoading, setIsLoading] = useState<boolean>(true); // Loading state for fetching options

  // Simulate fetching options for the select
  useEffect(() => {
    const fetchOptions = async () => {
      // Simulate a delay to mimic an API request
      setIsLoading(true);
      setTimeout(() => {
        // Simulated response from an API or database
        const simulatedOptions = [
          { value: "group1", label: "Senaste" },
          { value: "group2", label: "Dyrare" },
          { value: "noGroupOnServer", label: "Billigare" },
        ];
        setOptions(simulatedOptions);
        setIsLoading(false);
      }, 1000); // Simulated delay of 1 second
    };
    fetchOptions();
  }, []);

  // Handle the query change and update Zustand store
  const handleQueryChange = (value: BaseKey) => {
    setCurrentQuery(value as string); // Update the query (BaseKey) directly in Zustand store
  };

  return (
    <SearchableSelect
      options={options} // The options for the select
      onChange={handleQueryChange} // Handle change of selected query (BaseKey)
      placeholder={"Välj arbetslista"} // Placeholder text
      isLoading={isLoading || dataSourceIsLoading} // Show loading spinner while fetching options
      isTableCell={false}
      widthRem={18}
      isDisabled={isLoading || dataSourceIsLoading} // Disable if necessary
      defaultOpen={false} // Default open state
      isOpen={undefined} // Control the open state
      value={currentQuery} // Current selected value (from Zustand store as BaseKey)
    />
  );
};

export default PriceCheckSelectQuery