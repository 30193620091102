import { useEffect, useState } from 'react';
import { useLocaleStore } from 'stores/localeStore';
import { getUserInfoFromCache } from 'utility/helper';
import type { paths } from './schema.d.ts';
import createClient, { FetchOptions } from 'openapi-fetch';

const BASE_URL = process.env.REACT_APP_OPEN_API_URL;

type ApiClient = ReturnType<typeof createClient<paths>>;

export const useOpenApiClient = () => {
    const [client, setClient] = useState<ApiClient>();
    const localeState = useLocaleStore();
    const userInfo = getUserInfoFromCache();

    useEffect(() => {
        if (!client) {
            const options: FetchOptions<paths> = {
                baseUrl: BASE_URL,
                headers: {
                  'Authorization': `Bearer ${userInfo.token}`,
                },
            };
            const client = createClient<paths>(options);
            // const openApiClient = createAuthenticatedClient(userInfo.token);
            setClient(client);
        }
    }, [localeState.locale]);

    return client;
};