interface ResponseWrapperMany<T> {
  items: BaseItem<T>[];
  metaData: {
    get: MetaDataItem[];
    create: MetaDataItem[];
    update: MetaDataItem[];
  };
  totalCount: number;
  hasNextPage: boolean;
}

interface ResponseWrapper<T> {
  value: BaseItem<T>;
  metaData: {
    get: MetaDataItem[];
    create: MetaDataItem[];
    update: MetaDataItem[];
  };
}

export interface BaseItem<T> {
  id: number;
  createdAt: string;
  modifiedAt: string;
  item: T;
}

interface MetadataResponseWrapper {
  metaData: {
    get: MetaDataItem[];
    create: MetaDataItem[];
    update: MetaDataItem[];
  };
}

interface MetaDataItem {
  fullName: string;
  propertyPaths: string[];
  fields: Field[];
}

interface Field {
  name: string;
  fieldType: number;
  isEntity: boolean;
  isEnumeration: boolean;
  isEnum: boolean;
  enumInfo: EnumInfo[];
}

interface EnumInfo {
  initValue: number;
  enumValue: string;
}

enum SortOrderEnum {
  ASC = 0,
  DESC = 1,
}

interface GetManyRequestOptions {
  page?: number;
  pageSize?: number;
  order?: SortOrderEnum;
  orderByField?: string;
  filters?: string;
}

interface ErrorResponse {
  message: string;
}

export type { ResponseWrapper, ResponseWrapperMany, MetaDataItem, Field, EnumInfo, GetManyRequestOptions, MetadataResponseWrapper, ErrorResponse };
export { SortOrderEnum };
