import { useState } from 'react';
import { useOpenApiClient } from './base/useOpenApiClient';
import type { components } from './base/schema';
import { BaseItem, ErrorResponse } from 'interfaces/baseTypes';

type EntityMetaData = components["schemas"]["EntityMetaData"];
type Product = components["schemas"]["ProductResponse"];

interface ProductMany {
    metaData?: EntityMetaData;
    items?: BaseItem<Product>[];
    totalCount: number;
    hasNextPage: boolean;
}

const useProductOpenApiClient = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<ErrorResponse | null>(null);
    const client = useOpenApiClient();

    async function splitProduct(id: number, itemNos: string[]): Promise<void>{
        setLoading(true);
        setErrorMessage(null);
        try {
            await client?.PATCH('/api/products/{id}/split', {
                params: { path: { id } },
                body: {itemNos: itemNos}
            });
        } catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    return { splitProduct, loading, errorMessage };
}

export default useProductOpenApiClient;