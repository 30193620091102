import FullScreenLoader from "components/common/loadIndicators/fullScreenLoader";
import usePriceCheckStore from "stores/priceCheckStore";

const PriceCheckFullScreenLoader = () => {
  // const { dataSourceIsLoading } = usePriceCheckStore();
  return (
    <FullScreenLoader loading={false} message={`Laddar`}/>
  );
}

export default PriceCheckFullScreenLoader;