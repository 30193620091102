import { BaseKey, useNotification } from "@refinedev/core";
import { Checkbox, Modal } from "antd";
import { CheckboxChangeEventTarget } from "antd/es/checkbox/Checkbox";
import useProductOpenApiClient from "apiClients/useProductOpenApiClient";
import { IProductItem } from "interfaces/index";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sleep } from "utility/helper";

interface SplitProductModalProps {
    isOpen: boolean;
    onClose: () => void;
    variants: IProductItem[];
    mainItemNo: string | undefined;
    productId: BaseKey;
  }

const SplitProductModal: React.FC<SplitProductModalProps> = ({ isOpen, onClose, variants, mainItemNo, productId }) => {
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { splitProduct } = useProductOpenApiClient();
    const { open } = useNotification();
    const navigate = useNavigate();
    
    const handleOk = async () => {
        try {
          setIsLoading(true);
          await splitProduct(Number(productId), selectedItems);
          //await sleep(3000);
          open?.({
            type: "success",
            message: `Nya produkter has skapats av: ${selectedItems.join(", ")}`,
            description: "Produkten har delats",
          });
          Promise.resolve().then(async () => {
            await sleep(1800);
            navigate(`/products`);
          });  
          setIsLoading(false);
          onClose();
        } catch (error) {

        }finally{
          setIsLoading(false);
        }
    };
    
    const handleCheckboxChange = (itemNo: string) => {
        setSelectedItems(prevItems => {
          if (prevItems.includes(itemNo)) {
            return prevItems.filter(item => item !== itemNo);
          } else {
            return [...prevItems, itemNo];
          }
        });
    };

    const selectAllHandler = (event : CheckboxChangeEventTarget) => {
      if(event.checked)
        setSelectedItems(variants.filter(v => v.itemNo !== mainItemNo).map(variant => variant.itemNo));
      else
        setSelectedItems([]);
    }

    return (
      <>
        <Modal
          title="Dela produkt"
          open={isOpen}
          onCancel={() => !isLoading && onClose()}
          okText="Dela produkt"
          onOk={() => selectedItems.length > 0 && handleOk()}
          confirmLoading={isLoading}
        >
          <span style={{ marginBottom: "1rem", display: "block" }}>
            Välj vilka varianter som ska delas från produkten.
          </span>
          <Checkbox
            onChange={(e) => selectAllHandler(e.target)}
          >
            Välj alla
          </Checkbox>
          {variants?.map((variant) => (
            <React.Fragment key={variant.itemNo}>
              <Checkbox
                disabled={variant.itemNo === mainItemNo}
                onChange={() => handleCheckboxChange(variant.itemNo)}
                checked={selectedItems.includes(variant.itemNo)}
              >
                {variant.itemNo}
              </Checkbox>
              <br />
            </React.Fragment>
          ))}
        </Modal>
      </>
    );
  };

  export default SplitProductModal;