import { Button, Flex, Tooltip } from "antd";
import { ListChecks } from "lucide-react";
import usePriceCheckStore from "stores/priceCheckStore";

const PriceCheckToolBarButtons = () => {
  const { setSelectedListIsOpen } = usePriceCheckStore();
  return (
    <Flex
      gap={15}
      justify="center"
      style={{ position: "relative", alignItems: "end" }}
    >
      <Tooltip
        color={false ? "red" : "gray"}
        placement="topRight"
        title={"Produktlista"}
      >
        <Button
          style={{
            textAlign: "left",
            display: "flex",
            alignItems: "center",
            padding: "1.1rem",
            fontSize: "1rem",
            fontFamily: "HK Grotesk SemiBold",
            borderRadius: "10px",
          }}
          type="default"
          icon={<ListChecks size={"1em"} strokeWidth={"3px"} />}
          onClick={() => {
            setSelectedListIsOpen(true);
          }}
        >
          <span style={{ marginTop: "2px" }}>Skapa nya prisjämförelser</span>
        </Button>
      </Tooltip>
      <Tooltip
        color={false ? "red" : "gray"}
        placement="topRight"
        title={"Produktlista"}
      >
        <Button
          style={{
            textAlign: "left",
            display: "flex",
            alignItems: "center",
            padding: "1.1rem",
            fontSize: "1rem",
            fontFamily: "HK Grotesk SemiBold",
            borderRadius: "10px",
          }}
          type="primary"
          icon={<ListChecks size={"1em"} strokeWidth={"3px"} />}
          onClick={() => {
            setSelectedListIsOpen(true);
          }}
        >
          <span style={{ marginTop: "2px" }}>Produktlista</span>
        </Button>
      </Tooltip>
    </Flex>
  );
};

export default PriceCheckToolBarButtons;
