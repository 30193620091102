import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

type TokenRetriever = () => string | null;

class BaseHttpClient {
  private axiosInstance: AxiosInstance;
  private tokenRetriever: TokenRetriever | null = null;

  constructor(baseURL: string) {
    this.axiosInstance = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.axiosInstance.interceptors.request.use(
      (config) => {
        if (this.tokenRetriever) {
          const token = this.tokenRetriever();
          if (token && config.headers !== undefined) {
            config.headers['Authorization'] = `Bearer ${token}`;
          }
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  setTokenRetriever(retriever: TokenRetriever): void {
    this.tokenRetriever = retriever;
  }

  private async request<T>(config: AxiosRequestConfig): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.request(config);
      return response.data;
    } catch (error) {
      console.error('Error in HTTP request:', error);
      throw error;
    }
  }

  private buildQueryString(params: Record<string, any>): string {
    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        searchParams.append(key, String(value));
      }
    });
    return searchParams.toString();
  }

  async get<T>(url: string, params?: Record<string, any>, headers?: Record<string, string>): Promise<T> {
    const queryString = params ? this.buildQueryString(params) : '';
    const fullUrl = queryString ? `${url}?${queryString}` : url;
    return this.request<T>({ method: 'GET', url: fullUrl, headers });
  }

  async getMany<T>(url: string, params?: Record<string, any>, headers?: Record<string, string>): Promise<T[]> {
    const queryString = params ? this.buildQueryString(params) : '';
    const fullUrl = queryString ? `${url}?${queryString}` : url;
    return this.request<T[]>({ method: 'GET', url: fullUrl, headers });
  }

  async post<T>(url: string, data?: any, params?: Record<string, any>, headers?: Record<string, string>): Promise<T> {
    const queryString = params ? this.buildQueryString(params) : '';
    const fullUrl = queryString ? `${url}?${queryString}` : url;
    return this.request<T>({ method: 'POST', url: fullUrl, data, headers });
  }

  async postWithoutBody<T>(url: string, params?: Record<string, any>, headers?: Record<string, string>): Promise<T> {
    const queryString = params ? this.buildQueryString(params) : '';
    const fullUrl = queryString ? `${url}?${queryString}` : url;
    return this.request<T>({ method: 'POST', url: fullUrl, headers });
  }

  async put<T>(url: string, data?: any, params?: Record<string, any>, headers?: Record<string, string>): Promise<T> {
    const queryString = params ? this.buildQueryString(params) : '';
    const fullUrl = queryString ? `${url}?${queryString}` : url;
    return this.request<T>({ method: 'PUT', url: fullUrl, data, headers });
  }

  async putWithoutBody<T>(url: string, params?: Record<string, any>, headers?: Record<string, string>): Promise<T> {
    const queryString = params ? this.buildQueryString(params) : '';
    const fullUrl = queryString ? `${url}?${queryString}` : url;
    return this.request<T>({ method: 'PUT', url: fullUrl, headers });
  }

  async patch<T>(url: string, data?: any, params?: Record<string, any>, headers?: Record<string, string>): Promise<T> {
    const queryString = params ? this.buildQueryString(params) : '';
    const fullUrl = queryString ? `${url}?${queryString}` : url;
    return this.request<T>({ method: 'PATCH', url: fullUrl, data, headers });
  }

  async patchWithoutBody<T>(url: string, params?: Record<string, any>, headers?: Record<string, string>): Promise<T> {
    const queryString = params ? this.buildQueryString(params) : '';
    const fullUrl = queryString ? `${url}?${queryString}` : url;
    return this.request<T>({ method: 'PATCH', url: fullUrl, headers });
  }

  async delete<T>(url: string, params?: Record<string, any>, headers?: Record<string, string>): Promise<T> {
    const queryString = params ? this.buildQueryString(params) : '';
    const fullUrl = queryString ? `${url}?${queryString}` : url;
    return this.request<T>({ method: 'DELETE', url: fullUrl, headers });
  }
}

export default BaseHttpClient;