import PriceCheckSelectQuery from "./priceCheckSelectQuery";

const PriceCheckSelectQueryFilter = () => {
  return (
    <div style={{ position: "relative" }}>
      <PriceCheckSelectQuery isDisabled={false} />
    </div>
  );
};

export default PriceCheckSelectQueryFilter;
