import { MenuProps } from "antd";
import { Dropdown } from "antd/lib";
import { RefreshCcw } from "lucide-react";
import FullScreenLoader from "components/common/loadIndicators/fullScreenLoader";
import { useEffect, useState } from "react";
import useRegeneratePriceComparisons from "components/hooks/priceCheck/useRegeneratePriceComparisons";
import ActionsModalPriceCheck from "components/priceCheck/modals/actionsModalPriceCheck";
const UPDATE_PRICE_COMPARISON_TITLE = "Omgenerera prisjämförelser";

export const useMultiGridMenuItems = () => {
  const items: MenuProps["items"] = [
    {
      label: UPDATE_PRICE_COMPARISON_TITLE,
      key: 1,
      icon: <RefreshCcw size={"1em"} />,
    },
  ];

  return { menuItems: items };
};

export const PriceCheckSelectedUpdateItemsActions = () => {
  const { menuItems } = useMultiGridMenuItems();

  const {
    selectedUpdateItemsCount,
    loading: loadingRegeneratePriceComparisons,
    // putResponseMessage,
    error,
    status,
    updatePriceComparisonItems,
  } = useRegeneratePriceComparisons();

  const [loadingMessage, setLoadingMessage] = useState("");
  const [isSaveReportModalVisible, setIsSaveReportModalVisible] =
    useState(false);

  const handleMainButtonClick = () => {
    setLoadingMessage("Uppdaterar prisjämförelser...");
    updatePriceComparisonItems();
  };

  const onCloseSaveReportModal = () => {
    setIsSaveReportModalVisible(false);
  };

  const onMultiButtonClick = (e: any) => {
    if (e.key == 1) {
      handleMainButtonClick();
    } else if (e.key == 2) {
      setLoadingMessage("");
      console.log("Other action");
    }
  };

  useEffect(() => {
    setLoadingMessage("");
  }, [loadingRegeneratePriceComparisons]);

  return (
    <>
      <Dropdown.Button
        menu={{ items: menuItems, onClick: onMultiButtonClick }}
        disabled={selectedUpdateItemsCount === 0}
        onClick={handleMainButtonClick}
      >
        {UPDATE_PRICE_COMPARISON_TITLE}
      </Dropdown.Button>
      <FullScreenLoader loading={loadingRegeneratePriceComparisons} message={loadingMessage} />
      <ActionsModalPriceCheck
        isOpen={isSaveReportModalVisible}
        onClose={onCloseSaveReportModal}
      ></ActionsModalPriceCheck>
    </>
  );
};
