import usePriceTrackerApiClient from "apiClients/usePriceTrackerApiClient";
import { PriceTrackerDto } from "interfaces";
import { useEffect, useRef, useState } from "react";
import usePriceCheckStore from "stores/priceCheckStore";

const usePriceCheckInfiniteScroll = (pageSize = 10) => {
  // Zustand store
  const {
    resetState,
    addDataToDataSource,
    currentQuery,
    dataSourceIsLoading,
    setDataSourceIsLoading,
    dataSourceError,
    setDataSourceError,
  } = usePriceCheckStore(); 

  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [hasMoreItems, setHasMoreItems] = useState<boolean>(true);
  const targetRef = useRef<HTMLDivElement | null>(null);

  const { getPriceTrackers } = usePriceTrackerApiClient();
  const prevQueryRef = useRef<string | undefined>(currentQuery);
  const currentPageRef = useRef<number>(0); // Replace useState with useRef for current page

  // Fetch data function
  const fetchPriceTrackers = async () => {
    if (dataSourceIsLoading || !hasMoreItems) return;

    setDataSourceIsLoading(true);
    setDataSourceError({ isError: false, errorMessage: "", error: null });

    try {
      const response = await getPriceTrackers<PriceTrackerDto>({
        page: currentPageRef.current, // Use the ref instead of state
        pageSize: pageSize,
      });

      if (response) {
        const items = response?.items;
        addDataToDataSource(items);
        setTotalPages(Math.ceil(response.totalCount / pageSize));
        setTotalCount(response.totalCount);
        setHasMoreItems(response.hasNextPage);
        currentPageRef.current += 1; // Increment the ref value directly
      }
    } catch (e: any) {
      setDataSourceError({
        isError: true,
        errorMessage: e.message || "Error fetching data.",
        error: e,
      });
    } finally {
      setDataSourceIsLoading(false);
    }
  };

  // Refetch function
  const refetch = (keepQuery: boolean) => {
    resetAllStates(keepQuery);
    fetchPriceTrackers();
  };

  // Function to reset state and refs
  const resetAllStates = (keepQuery: boolean) => {
    resetState({ keepCurrentQuery: keepQuery });
    setHasMoreItems(true);
    setTotalPages(0);
    setTotalCount(0);
    currentPageRef.current = 0; // Reset the ref directly
  };

  // Reset states when query changes
  useEffect(() => {
    console.log('currentQuery', currentQuery);
    // If the query is empty or undefined, clear everything and stop fetching
    if (currentQuery === "" || currentQuery === undefined) {
      resetAllStates(true); // Clear all data and reset state
      return;
    }
  
    // Check if query has changed and proceed with fetching data if valid
    if (prevQueryRef.current !== currentQuery) {
      prevQueryRef.current = currentQuery;
      resetAllStates(true); // Reset state for new query
      fetchPriceTrackers(); // Trigger new fetch
    }
  }, [currentQuery]);

  // Setup IntersectionObserver for infinite scroll
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && hasMoreItems && !dataSourceIsLoading && currentQuery) {
          fetchPriceTrackers();
        }
      },
      {
        root: null, // Use the viewport as the root container (null means the whole screen)
        rootMargin: "100px", // Start loading when the element is 100px before it enters the viewport
        threshold: 0.01, // Trigger when 1% of the observed element is visible
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      observer.disconnect(); // Cleanup observer
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [hasMoreItems, dataSourceIsLoading, currentQuery]);

  return {
    infiniteItemsIsLoading: dataSourceIsLoading,
    infiniteItemsLength: totalCount,
    infiniteScrollError: dataSourceError,
    hasMoreItems,
    totalPages,
    fetchMoreData: fetchPriceTrackers,
    refetch,
    targetRef,
  };
};

export default usePriceCheckInfiniteScroll;


// import usePriceTrackerApiClient from "apiClients/usePriceTrackerApiClient";
// import { PriceTrackerDto } from "interfaces";
// import { useEffect, useRef, useState, useCallback } from "react";
// import usePriceCheckStore from "stores/priceCheckStore";

// const usePriceCheckInfiniteScroll = (pageSize = 10) => {
//   // Zustand store
//   const {
//     resetState,
//     addDataToDataSource,
//     currentQuery,
//     dataSourceIsLoading,
//     setDataSourceIsLoading,
//     dataSourceError,
//     setDataSourceError,
//   } = usePriceCheckStore(); 

//   const [totalPages, setTotalPages] = useState<number>(0);
//   const [totalCount, setTotalCount] = useState<number>(0);
//   const [currentPage, setCurrentPage] = useState<number>(0);
//   const [hasMoreItems, setHasMoreItems] = useState<boolean>(true);
//   const targetRef = useRef<HTMLDivElement | null>(null);

//   const { getPriceTrackers } = usePriceTrackerApiClient();
//   const prevQueryRef = useRef<string | undefined>(currentQuery);

//   // Fetch data function wrapped in useCallback to prevent re-declaration
//   const fetchPriceTrackers = useCallback(async () => {
//     if (dataSourceIsLoading || !hasMoreItems) return;

//     setDataSourceIsLoading(true);
//     setDataSourceError({ isError: false, errorMessage: "", error: null });

//     try {
//       const response = await getPriceTrackers<PriceTrackerDto>({
//         page: currentPage,
//         pageSize: pageSize,
//       });

//       console.log('response', response);
//       if (response) {
//         const items = response?.items;
//         addDataToDataSource(items);
//         setTotalPages(Math.ceil(response.totalCount / pageSize));
//         setTotalCount(response.totalCount);
//         setHasMoreItems(response.hasNextPage);
//         setCurrentPage((prevPage) => prevPage + 1);
//       }
//     } catch (e: any) {
//       setDataSourceError({
//         isError: true,
//         errorMessage: e.message || "Error fetching data.",
//         error: e,
//       });
//     } finally {
//       setDataSourceIsLoading(false);
//     }
//   }, [
//     currentPage,
//     pageSize,
//     currentQuery,
//     hasMoreItems,
//     dataSourceIsLoading,
//     getPriceTrackers,
//     addDataToDataSource,
//     setDataSourceError,
//     setDataSourceIsLoading,
//   ]);

//   // Refetch function
//   const refetch = useCallback((keepQuery: boolean) => {
//     resetAllStates(keepQuery);
//     fetchPriceTrackers();
//   }, [fetchPriceTrackers]);

//   // Function to reset state and refs
//   const resetAllStates = (keepQuery: boolean) => {
//     resetState({keepCurrentQuery: keepQuery});
//     setHasMoreItems(true);
//     setTotalPages(0);
//     setTotalCount(0);
//     setCurrentPage(0);
//   };

//   // Reset states when query changes
//   useEffect(() => {
//     // If the query is empty or undefined, clear everything and stop fetching
//     if (currentQuery === "" || currentQuery === undefined) {
//       resetAllStates(true); // Clear all data and reset state
//       return;
//     }
  
//     // Check if query has changed and proceed with fetching data if valid
//     if (prevQueryRef.current !== currentQuery) {
//       prevQueryRef.current = currentQuery;
//       resetAllStates(true); // Reset state for new query
//       fetchPriceTrackers(); // Trigger new fetch
//     }
//   }, [currentQuery]);

//   // Setup IntersectionObserver for infinite scroll
//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       (entries) => {
//         const entry = entries[0];
//         if (entry.isIntersecting && hasMoreItems && !dataSourceIsLoading) {
//           fetchPriceTrackers();
//         }
//       },
//       {
//         root: null, // Use the viewport as the root container (null means the whole screen)
//         rootMargin: "100px", // Start loading when the element is 50px before it enters the viewport
//         threshold: 0.01, // Trigger when 1% of the observed element is visible
//       }
//     );

//     if (targetRef.current) {
//       observer.observe(targetRef.current);
//     }

//     return () => {
//       observer.disconnect(); // Cleanup observer
//       if (targetRef.current) {
//         observer.unobserve(targetRef.current);
//       }
//     };
//   }, [hasMoreItems, dataSourceIsLoading, fetchPriceTrackers]);

//   return {
//     infiniteItemsIsLoading: dataSourceIsLoading,
//     infiniteItemsLength: totalCount,
//     infiniteScrollError: dataSourceError,
//     hasMoreItems,
//     totalPages,
//     fetchMoreData: fetchPriceTrackers,
//     refetch,
//     targetRef,
//   };
// };

// export default usePriceCheckInfiniteScroll;


