import { Button, Flex, Image, Modal } from "antd";
import styled from "@emotion/styled";
import { InfoCircleFilled, SaveOutlined } from "@ant-design/icons";
import { useState } from "react";
import MultiEditEnter from "../../../assets/gifs/multi-edit-cells.gif";
import LeaveCellSelection from "../../../assets/gifs/leave-cell-selection.gif";
import { DividerLine } from "components/separators/dividerLine";

const SaveReportModalContent = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .scrollable-container {
    max-height: 500px;
    overflow-y: auto;
    border: 1px solid #ddd;
    padding: 10px 10px 10px 0px;
    border-radius: 5px;
  }

  h2 {
    text-align: center;
  }

  .shortcuts-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .shortcuts {
    width: 45%;
  }

  .shortcuts h3 {
    text-align: center;
    margin-bottom: 10px;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    margin: 10px 0;
  }

  kbd {
    background-color: #eee;
    border: 1px solid #b4b4b4;
    border-radius: 3px;
    box-shadow: 1px 1px 1px #ccc;
    display: inline-block;
    font-size: 0.85em;
    padding: 2px 4px;
    margin: 0 2px;
    font-family: "Courier New", Courier, monospace;
  }
`;

const InformationModalPriceCheck = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const handleCloseModal = async () => {
    setIsModalVisible(false);
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      <Button
        onClick={handleOpenModal}
        type="text"
        size="middle"
        icon={
          <InfoCircleFilled style={{ fontSize: "23px", color: "#195178" }} />
        }
      ></Button>
      <Modal
        title="Information multiredigering"
        open={isModalVisible}
        footer={null}
        closable={true}
        onCancel={handleCloseModal}
      >
        <SaveReportModalContent>
          {/* <div>
            <h2>Användning</h2>
            <p> </p>
          </div> */}

          <div className="scrollable-container">
            <h2>Åtgärder</h2>
            <div className="shortcuts-container">
              <div className="shortcuts first">
                <ul>
                  <li>
                    Ångra tabellredigering - Knapp <kbd>Ångra senaste</kbd>
                  </li>
                </ul>
              </div>
              <div className="shortcuts">
                <ul>
                  <li>
                    Hämta/ladda om data - Knapp <kbd>Ladda om</kbd>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="scrollable-container">
            <h2>Kortkommandon/keyboard</h2>

            <div className="shortcuts-container">
              <div className="shortcuts">
                <h3>Windows</h3>
                <ul>
                  <li>
                    <kbd>CTRL</kbd> + <kbd>C</kbd> - Kopiera cellvärde
                  </li>
                  <li>
                    <kbd>CTRL</kbd> + <kbd>V</kbd> - Klistra in cellvärde
                  </li>
                </ul>
              </div>
              <div className="shortcuts">
                <h3>macOS</h3>
                <ul>
                  <li>
                    <kbd>⌘</kbd> + <kbd>C</kbd> - Kopiera cellvärde
                  </li>
                  <li>
                    <kbd>⌘</kbd> + <kbd>V</kbd> - Klistra in cellvärde
                  </li>
                </ul>
              </div>
            </div>
            <DividerLine />
            <div className="shortcuts-container">
              <div className="shortcuts">
                <ul>
                  <li>
                    <kbd>ESC</kbd> - Lämna cellmarkeing
                    <Image
                      src={LeaveCellSelection}
                      preview={false}
                      style={{
                        marginTop: "0.3rem",
                        maxHeight: "200px",
                        border: "1px solid gray",
                      }}
                    />
                  </li>
                </ul>
              </div>
              <div className="shortcuts">
                <ul>
                  <li>
                    <kbd>ENTER</kbd> - Ersätt cellvärden
                    <Image
                      src={MultiEditEnter}
                      preview={false}
                      style={{
                        marginTop: "0.3rem",
                        maxHeight: "200px",
                        border: "1px solid gray",
                      }}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <Flex
            justify="flex-end"
            gap={"0.5rem"}
            style={{ marginTop: "0.5rem", paddingRight: "0px" }}
          >
            <Button type="primary" onClick={handleCloseModal}>
              Stäng
            </Button>
          </Flex>
        </SaveReportModalContent>
      </Modal>
    </>
  );
};

export default InformationModalPriceCheck;
